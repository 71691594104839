export const SONG_DB = {
  artists: {
    '1': { artistId: '1', artist: 'David Bazan' },
    '2': { artistId: '2', artist: 'Pedro the Lion' },
    '3': { artistId: '3', artist: 'Lo Tom' },
    '4': { artistId: '4', artist: 'Headphones' },
    '5': { artistId: '5', artist: 'Overseas' },
  },
  albums: {
    '1': { albumId: '1', artistId: '1', album: 'Bazan Monthly Volume 1' },
    '2': { albumId: '2', artistId: '1', album: 'Blanco' },
    '3': { albumId: '3', artistId: '1', album: 'Care' },
    '4': { albumId: '4', artistId: '1', album: 'Curse Your Branches' },
    '5': { albumId: '5', artistId: '1', album: 'Dark Sacred Night' },
    '6': { albumId: '6', artistId: '1', album: 'Fewer Moving Parts' },
    '7': { albumId: '7', artistId: '1', album: 'Strange Negotiations' },
    '8': { albumId: '8', artistId: '4', album: 'Headphones' },
    '9': { albumId: '9', artistId: '3', album: 'Lo Tom' },
    '10': { albumId: '10', artistId: '3', album: 'LP 2' },
    '11': { albumId: '11', artistId: '2', album: 'Achilles Heel' },
    '12': { albumId: '12', artistId: '2', album: 'Control' },
    '13': { albumId: '13', artistId: '2', album: 'Havasu' },
    '14': { albumId: '14', artistId: '2', album: "It's Hard to Find a Friend" },
    '15': { albumId: '15', artistId: '2', album: 'Phoenix' },
    '16': { albumId: '16', artistId: '2', album: 'Progress' },
    '17': {
      albumId: '17',
      artistId: '2',
      album: 'The Only Reason I Feel Secure',
    },
    '18': { albumId: '18', artistId: '2', album: 'Whole EP' },
    '19': { albumId: '19', artistId: '2', album: 'Winners Never Quit' },
    '20': { albumId: '20', artistId: '1', album: 'Bazan 2021 #1' },
    '21': { albumId: '21', artistId: '1', album: 'Bazan 2021 #2' },
    '22': { albumId: '22', artistId: '5', album: 'Overseas' },
    '23': { albumId: '23', artistId: '1', album: 'Bazan Monthly Volume 2' },
  },
  songs: {
    '1': {
      songId: '1',
      artistId: '1',
      albumId: '1',
      song: 'Impermanent Record',
    },
    '2': { songId: '2', artistId: '1', albumId: '1', song: 'Deny Myself' },
    '3': { songId: '3', artistId: '1', albumId: '1', song: 'Nobodys Perfect' },
    '8': { songId: '8', artistId: '1', albumId: '1', song: 'Ill At Ease' },
    '15': { songId: '15', artistId: '1', albumId: '23', song: 'Island Ocean' },
    '17': { songId: '17', artistId: '1', albumId: '23', song: 'Goes On' },
    '18': { songId: '18', artistId: '1', albumId: '23', song: 'Animals' },
    '19': { songId: '19', artistId: '1', albumId: '23', song: 'Over My Eyes' },
    '20': { songId: '20', artistId: '1', albumId: '23', song: 'Here We Are' },
    '21': { songId: '21', artistId: '1', albumId: '2', song: 'Both Hands' },
    '22': { songId: '22', artistId: '1', albumId: '2', song: 'Oblivion' },
    '23': { songId: '23', artistId: '1', albumId: '2', song: 'Kept Secrets' },
    '24': { songId: '24', artistId: '1', albumId: '2', song: 'With You' },
    '25': {
      songId: '25',
      artistId: '1',
      albumId: '2',
      song: 'Trouble With Boys',
    },
    '26': { songId: '26', artistId: '1', albumId: '2', song: 'Teardrops' },
    '27': {
      songId: '27',
      artistId: '1',
      albumId: '2',
      song: 'Little Landslide',
    },
    '28': {
      songId: '28',
      artistId: '1',
      albumId: '2',
      song: 'Someone Elses Bet',
    },
    '29': { songId: '29', artistId: '1', albumId: '2', song: 'Over Again' },
    '30': { songId: '30', artistId: '1', albumId: '2', song: 'Little Motor' },
    '31': { songId: '31', artistId: '1', albumId: '3', song: 'Care' },
    '32': { songId: '32', artistId: '1', albumId: '3', song: 'Up All Night' },
    '33': {
      songId: '33',
      artistId: '1',
      albumId: '3',
      song: 'Disappearing Ink',
    },
    '34': {
      songId: '34',
      artistId: '1',
      albumId: '3',
      song: 'Sparkling Water',
    },
    '35': {
      songId: '35',
      artistId: '1',
      albumId: '3',
      song: 'Permanent Record',
    },
    '36': { songId: '36', artistId: '1', albumId: '3', song: 'Make Music' },
    '37': { songId: '37', artistId: '1', albumId: '3', song: 'Lazerbeams' },
    '38': { songId: '38', artistId: '1', albumId: '3', song: 'Inner Lives' },
    '39': { songId: '39', artistId: '1', albumId: '3', song: 'Keep Trying' },
    '40': {
      songId: '40',
      artistId: '1',
      albumId: '3',
      song: 'The Ballad Of Pedro Y Blanco',
    },
    '44': { songId: '44', artistId: '1', albumId: '4', song: 'Hard To Be' },
    '45': {
      songId: '45',
      artistId: '1',
      albumId: '4',
      song: 'Bless This Mess',
    },
    '46': {
      songId: '46',
      artistId: '1',
      albumId: '4',
      song: 'Please Baby Please',
    },
    '47': {
      songId: '47',
      artistId: '1',
      albumId: '4',
      song: 'Curse Your Branches',
    },
    '48': {
      songId: '48',
      artistId: '1',
      albumId: '4',
      song: 'Harmless Sparks',
    },
    '49': { songId: '49', artistId: '1', albumId: '4', song: 'When We Fell' },
    '50': { songId: '50', artistId: '1', albumId: '4', song: 'Lost My Shape' },
    '51': {
      songId: '51',
      artistId: '1',
      albumId: '4',
      song: 'Bearing Witness',
    },
    '52': { songId: '52', artistId: '1', albumId: '4', song: 'Heavy Breath' },
    '53': { songId: '53', artistId: '1', albumId: '4', song: 'In Stitches' },
    '54': {
      songId: '54',
      artistId: '1',
      albumId: '5',
      song: 'All I Want For Christmas',
    },
    '55': {
      songId: '55',
      artistId: '1',
      albumId: '5',
      song: 'Happy Xmas War Is Over',
    },
    '56': {
      songId: '56',
      artistId: '1',
      albumId: '5',
      song: 'Away In A Manger',
    },
    '57': {
      songId: '57',
      artistId: '1',
      albumId: '5',
      song: 'God Rest Ye Merry Gentlemen',
    },
    '58': {
      songId: '58',
      artistId: '1',
      albumId: '5',
      song: 'I Heard The Bells On Christmas Day',
    },
    '59': { songId: '59', artistId: '1', albumId: '5', song: 'Jingle Bells' },
    '60': { songId: '60', artistId: '1', albumId: '5', song: 'Silent Night' },
    '62': {
      songId: '62',
      artistId: '1',
      albumId: '5',
      song: 'O Little Town Of Bethlehem',
    },
    '63': {
      songId: '63',
      artistId: '1',
      albumId: '5',
      song: 'Wish My Kids Were Here',
    },
    '64': {
      songId: '64',
      artistId: '1',
      albumId: '6',
      song: 'Selling Advertising',
    },
    '65': { songId: '65', artistId: '1', albumId: '6', song: 'How I Remember' },
    '66': {
      songId: '66',
      artistId: '1',
      albumId: '6',
      song: 'Fewer Broken Pieces',
    },
    '67': {
      songId: '67',
      artistId: '1',
      albumId: '6',
      song: 'Cold Beer And Cigarettes',
    },
    '68': {
      songId: '68',
      artistId: '1',
      albumId: '6',
      song: 'Backwoods Nation',
    },
    '73': {
      songId: '73',
      artistId: '1',
      albumId: '7',
      song: 'Wolves At The Door',
    },
    '74': {
      songId: '74',
      artistId: '1',
      albumId: '7',
      song: 'Level With Yourself',
    },
    '75': { songId: '75', artistId: '1', albumId: '7', song: 'Future Past' },
    '76': { songId: '76', artistId: '1', albumId: '7', song: 'People' },
    '77': { songId: '77', artistId: '1', albumId: '7', song: 'Virginia' },
    '78': { songId: '78', artistId: '1', albumId: '7', song: 'Eating Paper' },
    '79': { songId: '79', artistId: '1', albumId: '7', song: 'Messes' },
    '80': { songId: '80', artistId: '1', albumId: '7', song: 'Dont Change' },
    '81': {
      songId: '81',
      artistId: '1',
      albumId: '7',
      song: 'Strange Negotiations',
    },
    '82': { songId: '82', artistId: '1', albumId: '7', song: 'Wont Let Go' },
    '84': {
      songId: '84',
      artistId: '4',
      albumId: '8',
      song: 'Gas And Matches',
    },
    '85': { songId: '85', artistId: '4', albumId: '8', song: 'Shit Talker' },
    '86': { songId: '86', artistId: '4', albumId: '8', song: 'Hot Girls' },
    '87': {
      songId: '87',
      artistId: '4',
      albumId: '8',
      song: 'I Never Wanted You',
    },
    '88': { songId: '88', artistId: '4', albumId: '8', song: 'Major Cities' },
    '89': {
      songId: '89',
      artistId: '4',
      albumId: '8',
      song: 'Natural Disaster',
    },
    '90': { songId: '90', artistId: '4', albumId: '8', song: 'Hello Operator' },
    '91': { songId: '91', artistId: '4', albumId: '8', song: 'Pink And Brown' },
    '92': { songId: '92', artistId: '4', albumId: '8', song: 'Wise Blood' },
    '93': { songId: '93', artistId: '4', albumId: '8', song: 'Slow Car Crash' },
    '94': { songId: '94', artistId: '4', albumId: '8', song: 'The Five Chord' },
    '95': { songId: '95', artistId: '3', albumId: '9', song: 'Covered Wagon' },
    '96': { songId: '96', artistId: '3', albumId: '9', song: 'Overboard' },
    '97': { songId: '97', artistId: '3', albumId: '9', song: 'Bubblegum' },
    '98': { songId: '98', artistId: '3', albumId: '9', song: 'Bad Luck Charm' },
    '99': {
      songId: '99',
      artistId: '3',
      albumId: '9',
      song: 'Another Mistake',
    },
    '100': {
      songId: '100',
      artistId: '3',
      albumId: '9',
      song: 'Find The Shrine',
    },
    '101': { songId: '101', artistId: '3', albumId: '9', song: 'Pretty Cool' },
    '102': { songId: '102', artistId: '3', albumId: '9', song: 'Lower Down' },
    '103': { songId: '103', artistId: '3', albumId: '10', song: 'Start Payin' },
    '104': { songId: '104', artistId: '3', albumId: '10', song: 'Suck It Up' },
    '105': { songId: '105', artistId: '3', albumId: '10', song: 'Outta Here' },
    '106': {
      songId: '106',
      artistId: '3',
      albumId: '10',
      song: 'I Need Relief',
    },
    '107': {
      songId: '107',
      artistId: '3',
      albumId: '10',
      song: 'No Margin Of Error',
    },
    '108': { songId: '108', artistId: '3', albumId: '10', song: 'The Show' },
    '109': {
      songId: '109',
      artistId: '3',
      albumId: '10',
      song: 'Dont Look At Me',
    },
    '110': { songId: '110', artistId: '3', albumId: '10', song: 'In A Van' },
    '111': {
      songId: '111',
      artistId: '2',
      albumId: '11',
      song: 'Bands With Managers',
    },
    '112': {
      songId: '112',
      artistId: '2',
      albumId: '11',
      song: 'Foregone Conclusions',
    },
    '113': {
      songId: '113',
      artistId: '2',
      albumId: '11',
      song: 'The Fleecing',
    },
    '114': { songId: '114', artistId: '2', albumId: '11', song: 'Discretion' },
    '115': { songId: '115', artistId: '2', albumId: '11', song: 'Arizona' },
    '116': {
      songId: '116',
      artistId: '2',
      albumId: '11',
      song: 'Keep Swinging',
    },
    '117': {
      songId: '117',
      artistId: '2',
      albumId: '11',
      song: 'Transcontinental',
    },
    '118': { songId: '118', artistId: '2', albumId: '11', song: 'I Do' },
    '119': {
      songId: '119',
      artistId: '2',
      albumId: '11',
      song: 'A Simple Plan',
    },
    '120': {
      songId: '120',
      artistId: '2',
      albumId: '11',
      song: 'Start Without Me',
    },
    '121': { songId: '121', artistId: '2', albumId: '11', song: 'The Poison' },
    '122': { songId: '122', artistId: '2', albumId: '12', song: 'Options' },
    '123': { songId: '123', artistId: '2', albumId: '12', song: 'Rapture' },
    '124': { songId: '124', artistId: '2', albumId: '12', song: 'Penetration' },
    '125': {
      songId: '125',
      artistId: '2',
      albumId: '12',
      song: 'Indian Summer',
    },
    '126': { songId: '126', artistId: '2', albumId: '12', song: 'Progress' },
    '127': { songId: '127', artistId: '2', albumId: '12', song: 'Magazine' },
    '128': { songId: '128', artistId: '2', albumId: '12', song: 'Rehearsal' },
    '129': { songId: '129', artistId: '2', albumId: '12', song: 'Second Best' },
    '130': {
      songId: '130',
      artistId: '2',
      albumId: '12',
      song: 'Priests And Paramedics',
    },
    '131': { songId: '131', artistId: '2', albumId: '12', song: 'Rejoice' },
    '132': {
      songId: '132',
      artistId: '2',
      albumId: '13',
      song: 'Dont Wanna Move',
    },
    '133': { songId: '133', artistId: '2', albumId: '13', song: 'Too Much' },
    '134': {
      songId: '134',
      artistId: '2',
      albumId: '13',
      song: 'First Drum Set',
    },
    '135': {
      songId: '135',
      artistId: '2',
      albumId: '13',
      song: 'Teenage Sequencer',
    },
    '136': {
      songId: '136',
      artistId: '2',
      albumId: '13',
      song: 'Own Valentine',
    },
    '137': {
      songId: '137',
      artistId: '2',
      albumId: '13',
      song: 'Making The Most Of It',
    },
    '138': { songId: '138', artistId: '2', albumId: '13', song: 'Old Wisdom' },
    '139': { songId: '139', artistId: '2', albumId: '13', song: 'Stranger' },
    '140': {
      songId: '140',
      artistId: '2',
      albumId: '13',
      song: 'Good Feeling',
    },
    '141': { songId: '141', artistId: '2', albumId: '13', song: 'Lost Myself' },
    '142': {
      songId: '142',
      artistId: '2',
      albumId: '14',
      song: 'Of Up And Coming Monarchs',
    },
    '143': {
      songId: '143',
      artistId: '2',
      albumId: '14',
      song: 'The Longer I Lay Here',
    },
    '144': { songId: '144', artistId: '2', albumId: '14', song: 'Big Trucks' },
    '145': {
      songId: '145',
      artistId: '2',
      albumId: '14',
      song: 'Suspect Fled The Scene',
    },
    '146': {
      songId: '146',
      artistId: '2',
      albumId: '14',
      song: 'Bad Diary Days',
    },
    '147': {
      songId: '147',
      artistId: '2',
      albumId: '14',
      song: 'The Longest Winter',
    },
    '148': {
      songId: '148',
      artistId: '2',
      albumId: '14',
      song: 'When They Really Get To Know You They Will Run',
    },
    '149': {
      songId: '149',
      artistId: '2',
      albumId: '14',
      song: 'Of Minor Prophets And Their Prostitute Wives',
    },
    '150': { songId: '150', artistId: '2', albumId: '14', song: 'The Bells' },
    '151': {
      songId: '151',
      artistId: '2',
      albumId: '14',
      song: 'The Secret Of The Easy Yoke',
    },
    '152': { songId: '152', artistId: '2', albumId: '14', song: 'The Well' },
    '153': { songId: '153', artistId: '2', albumId: '14', song: 'Promise' },
    '154': { songId: '154', artistId: '2', albumId: '15', song: 'Sunrise' },
    '155': { songId: '155', artistId: '2', albumId: '15', song: 'Yellow Bike' },
    '156': { songId: '156', artistId: '2', albumId: '15', song: 'Clean Up' },
    '157': {
      songId: '157',
      artistId: '2',
      albumId: '15',
      song: 'Powerful Taboo',
    },
    '158': { songId: '158', artistId: '2', albumId: '15', song: 'Model Homes' },
    '159': { songId: '159', artistId: '2', albumId: '15', song: 'Piano Bench' },
    '160': { songId: '160', artistId: '2', albumId: '15', song: 'Circle K' },
    '161': {
      songId: '161',
      artistId: '2',
      albumId: '15',
      song: 'Quietest Friend',
    },
    '162': {
      songId: '162',
      artistId: '2',
      albumId: '15',
      song: 'Tracing The Grid',
    },
    '163': {
      songId: '163',
      artistId: '2',
      albumId: '15',
      song: 'Black Canyon',
    },
    '164': { songId: '164', artistId: '2', albumId: '15', song: 'My Phoenix' },
    '165': {
      songId: '165',
      artistId: '2',
      albumId: '15',
      song: 'All Seeing Eye',
    },
    '166': {
      songId: '166',
      artistId: '2',
      albumId: '15',
      song: 'Leaving The Valley',
    },
    '167': {
      songId: '167',
      artistId: '2',
      albumId: '16',
      song: 'June 18 1976',
    },
    '168': {
      songId: '168',
      artistId: '2',
      albumId: '16',
      song: 'April 6 2039',
    },
    '177': {
      songId: '177',
      artistId: '2',
      albumId: '17',
      song: 'Criticism As Inspiration',
    },
    '178': {
      songId: '178',
      artistId: '2',
      albumId: '17',
      song: 'I Am Always The One Who Calls',
    },
    '179': { songId: '179', artistId: '2', albumId: '17', song: 'Invention' },
    '180': {
      songId: '180',
      artistId: '2',
      albumId: '17',
      song: 'Letter From A Concerned Follower',
    },
    '181': {
      songId: '181',
      artistId: '2',
      albumId: '17',
      song: 'Be Thou My Vision',
    },
    '183': {
      songId: '183',
      artistId: '2',
      albumId: '17',
      song: 'Diamond Ring',
    },
    '191': { songId: '191', artistId: '2', albumId: '18', song: 'Nothing' },
    '192': { songId: '192', artistId: '2', albumId: '18', song: 'Fix' },
    '193': {
      songId: '193',
      artistId: '2',
      albumId: '18',
      song: 'Almost There',
    },
    '194': { songId: '194', artistId: '2', albumId: '18', song: 'Whole' },
    '195': { songId: '195', artistId: '2', albumId: '18', song: 'Lullaby' },
    '196': { songId: '196', artistId: '2', albumId: '18', song: 'Hymn' },
    '197': {
      songId: '197',
      artistId: '2',
      albumId: '19',
      song: 'Slow And Steady Wins The Race',
    },
    '198': {
      songId: '198',
      artistId: '2',
      albumId: '19',
      song: 'Simple Economics',
    },
    '199': {
      songId: '199',
      artistId: '2',
      albumId: '19',
      song: 'To Protect The Family Name',
    },
    '200': {
      songId: '200',
      artistId: '2',
      albumId: '19',
      song: 'A Mind Of Her Own',
    },
    '201': {
      songId: '201',
      artistId: '2',
      albumId: '19',
      song: 'Never Leave A Job Half Done',
    },
    '202': {
      songId: '202',
      artistId: '2',
      albumId: '19',
      song: 'Eye On The Finish Line',
    },
    '203': {
      songId: '203',
      artistId: '2',
      albumId: '19',
      song: 'Bad Things To Such Good People',
    },
    '204': {
      songId: '204',
      artistId: '2',
      albumId: '19',
      song: 'Winners Never Quit',
    },
    '205': {
      songId: '205',
      artistId: '1',
      albumId: '20',
      song: 'Your Bearings',
    },
    '206': { songId: '206', artistId: '1', albumId: '21', song: 'L/S' },
    '207': {
      songId: '207',
      artistId: '5',
      albumId: '22',
      song: 'Came With The Frame',
    },
    '208': { songId: '208', artistId: '5', albumId: '22', song: 'Hellp' },
    '209': {
      songId: '209',
      artistId: '5',
      albumId: '22',
      song: 'Here Wish You Were',
    },
    '210': { songId: '210', artistId: '5', albumId: '22', song: 'Old Love' },
    '211': { songId: '211', artistId: '5', albumId: '22', song: 'Down Below' },
  },
}
