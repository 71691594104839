export const LYRICS = [
  'I heard the bells on Christmas Day',
  'I calculated then hung my head in shame',
  'By those who have no doubt',
  'Every single one of you is an American man',
  'There’s always someone above you',
  'Daddy\'s got you',
  'Without ruining our lives',
  'I could hear the church bells ringing',
  'We\'re starting to worry',
  'Not a word since after the fall',
  'Peace on Earth',
  'She just has a funny way of loving me',
  'How badly shook I was',
  'It seems like you\'d be tired of losing face',
  'Hired to hit the farmer by the farmer\'s asshole son',
  'The power\'s out in our first apartment',
  'The wind blows up the dirt',
  'Might take a couple beats for me to decide',
  'It\'s just a flesh wound with a proper noun',
  'But only after pills and drinks',
  'What hatred and revenge were all about',
  'Eating paper I swung from the trees',
  'We were walking, holding hands',
  'By those who have no doubt',
  'Then if you get tired of making taste for free',
  'And even though you haven\'t any answers',
  'I swore I\'d be careful not to',
  'The scorned flowers and candy',
  'With a short rope',
  'You\'re on liver number two',
  'the man beneath the truck in several pieces',
  'When something wakes me up',
  'And spoke with great conviction',
  'Lucky and careful we dodged our worst fears',
  'Be civil and not make a scene',
  'Tryin\' to find a place in this mess',
  'When i lived alone i did as i please',
  'The silent stars go by',
  'Kid sister said "How much have you saved?"',
  'He took more than fifty eyewitness accounts',
  'which fruit you really wanna bite into',
  'And I can\'t bring this one with me',
  'Feet on the floor',
  'Cause man i’ve gotta get there on my own',
  'Such an awful, tragic night',
  'Am I asleep? Am I awake?',
  'Where the wheel stops, no one knows',
  'Twenty days off the sauce',
  'In through your nose and out through your little mouth',
  'But who he roots for in a fight',
  'Times like these',
  'When I touch down in Texas, land in Dallas/Fort Worth',
  'We talk on the phone every night',
  'Be born in us today',
  'Who forgets how it feels',
  'Love rides in the back',
  'Take the boardwalk by storm',
  'As better men use brand new math',
  'Little motor behind your eyes',
  'Just a front row seat',
  'Little landslide on the brink',
  'Looking down from that stain glass steeple',
  'Know you\'ll stare at that face a long time before he winks',
  'But the band director shook his head, "forgеt it',
  'Then a grown man might',
  'It just takes a while',
  'Man, these kids are doomed',
  'I let it wreck me',
  'You got drunk more so than you\'d ever been',
  'Made it boom, crack, boom',
  'Didn\'t we make music',
  'You know I always said that I would kill you',
  'To dodge the draft once more',
  'It seems the hole was always twice as big no matter what it was',
  'I need three pours just to walk through the door',
  'But I\'m left to bleed to death',
  'In your prime',
  'You start to tell it all the time',
  'The farmer and his sweet young wife slept soundly in his bed',
  'Dad, I broke my promise to you',
  'And you\'re solid on thе clarinet"',
  'And she thinks she\'s going to squeal',
  'One good friend remarks with a rightfully angry',
  'Whose fleecing makes a fool of me',
  'Click clack, now handicapped',
  'But what the Lord gives, he can take away',
  'So your ancient poems delight',
  'And stay by my side until morning is nigh',
  'Driving my favorite route',
  'Making you the punchline of some random thing I wrote',
  'Before your face warms in the sun, in the sun',
  'Go early to their new self-making beds',
  'Fifth grade in the lunchroom, fickle friends were telling jokes',
  'You know I chase around this desert because I think that’s where you’ll be',
  'His head swinging round',
  'One of the first boots on the ground',
  '"Buddy just calm down, you\'ll be alright."',
  'You think of leaving but you stick around',
  'The storm blows into a flood, then disappears',
  'Lest we hear it from the rocks and the trees',
  'And ponder the weight of an apple',
  'My head’s under water',
  'Yeah I guess you probably could',
  'Might take a couple beats for me to decide',
  'All the plans we made together',
  'And I\'m never gonna rest in peace',
  'But man you’ve gotta find the truth',
  'Don\'t listen to the static, just listen to the drums',
  'On a six-week solo drive',
  'Clear as a bell are the short breaths',
  'Might never catch up',
  'As you in your \'87 Trans Am?',
  'With only two weeks to go',
  'To ever get ahead',
  'At least in my dreams',
  'And on the front porch or on an airplane on vacation',
  'You hide it under a bushel but the light comes shining through',
  'Slyly you invite them',
  'I understand I can\'t sweat what mystery hides',
  'Given the time I think she would have understood',
  'I spent it all at Circle K',
  'And a new one just begun',
  'Junior high legs, blond hair gone brown',
  'Who or what controls the fates of men I cannot say',
  'God bless the woman who still can’t sleep',
  'It can ever be found',
  'Healthy skin, perfect teeth',
  'Now three days sober',
  'The devil beats his wife again',
  'I remember as I bleed',
  'The way you laughed at me threw off a little spark',
  'cloud of witnesses',
  'Tall wet grass, whose flesh makes fools of grazing sheep',
  'Such a romantic place to hide from taking my lumps',
  'Be civil and not make a scene',
  'Maybe then you\'ll have some more to say',
  'Depriving this nice hotel room',
  'God bless the weeds in the wheat',
  'But after all',
  '"How\'d it go today" comes a question in the quiet car ride home',
  'I’m orbiting North Mountain pretty close',
  'The bully loved her cactus',
  'Right at each other with spare time to say',
  'And you learn from them',
  'In a one-horse open sleigh',
  'Scissors falls apart',
  'No one wants to stop',
  'Calling the doctors of spin and the smoke screen',
  'The sperm swims for the egg; the finger for the ring',
  'I kissed her forehead and rubbed her little tummy',
  'Though the words had not been said yet',
  'Get me outta hеre',
  'You saw a photo of your younger self',
  'God rest ye merry gentlemen',
  'So you see right through me',
  ' get to where I\'m going --',
  'Oh Union Hills & 35th Avenue',
  'You only trust the foxes',
  'She packed your favorite blue shoes',
  'Blood is gonna splatter',
  'So tell yourself again',
  'I woke up faced the other way',
  'The march you made is wide',
  'I store my thoughts',
  'Baking in the driveway blast',
  'You stick it to the black and blue around you',
  'If someone tells you the truth',
  'The virtue in my veins reply',
  'It\'s weird to think of all the things',
  'The flesh of my own flesh',
  'I thought I knew what I was doing',
  'Everybody knows the songs are on their way out',
  'It\'s weird to think of all the things',
  'You can make it swing',
  'You\'ve heard the story',
  'But something was wrong up on stage',
  'And I\'d throw mine out',
  'Please and thank you',
  'My heart thumping in my chest',
  'Fifth grade in the lunchroom, fickle friends were telling jokes',
  'Deep green hills, whose shoulders fade into thick grey',
  'And I\'m down at the track, I\'m just calling to check in',
  'The river keeps repeating',
  'Clean up clean up clean up clean up',
  'To watch your true love pack her things and drive away',
  'The members\' faces were smiling',
  'The farmer and his sweet young wife slept soundly in his bed',
  'Upon the ocean floor',
  'This all hangs on luck',
  'So you climb under the covers',
  'Come and get yours in the sun',
  'I climb the rock, still make a home in the cleft',
  'Your purse hit the wind shield when I locked the brakes',
  'With no wrong answers',
  'So naive, I\'d have you believe',
  'Bites on the ankle',
  'Write out the notes measure for measure',
  'Mistakes that haunt us all our days',
  'Bred to believe, taught to obey, hеard the call and moved away',
  'Do I drive around with my high beams on?',
  'I could have sworn I saw it, too',
  'I don\'t like girls the way they are',
  'We fought for a decade corruption and greed',
  'The empty movements that once were so inspired',
  'The hopes and fears of all the years',
  'Then whats with you staring at the floor',
  'Then later plays them back',
  'Just for a few habit-forming years',
  'I know you never meant to leave the way you came',
  'God bless the weeds in the wheat',
  'The virtue in my veins reply',
  'Or that you\'d have the last laugh',
  'Of gifts beneath the tree',
  'Of swift justice',
  'To which I in pride responded',
  'That Jesus said He\'d fill my needs, but my heart still bleeds',
  'What a cruel God we\'ve got',
  'The candidate wrote furiously',
  'Count it a blessing',
  'My body bangs and twitches',
  'Check your revelation',
  '\'Cause ever since Beverly Hills Cop, "The Heat Is On"',
  'Junior high legs, blond hair gone brown',
  'It seems like you\'d be tired of losing face',
  'At a tender age I learned to scrub my feelings',
  'And when someone pulls my strings',
  'If you were to give me proof',
  'That they prepare a feast',
  'The way we had of never quite meeting eyes',
  'Someone had a knife',
  'Style is taking over',
  'You can lay down your armor and have no fear',
  'You pulled me aside',
  'And peace in the valley a few days a month',
  'Is now in ruins',
  'He started pulling apart at the seams',
  'Time for me to live within my means',
  'Until the snow is white with ocean foam',
  'Who shall I blame for this sweet and heavy trouble?',
  'They were looking to me to land the final blow',
  'Fewer moving parts means fewer broken pieces',
  'On someone else\'s bones',
  'But, darling, death will have to pry my fingers loose',
  'Some folks think we\'re better now',
  'North AM transcontinental',
  'If you carry them by yourself',
  'The fruit fly sighs, as evening fades',
  'Cause I was bored and cause I could',
  'I awoke to find you home',
  'And warmly they expect me at their family Christmas party',
  'Child birth is painful',
  'To this old frame',
  'Looks like I\'m gonna be fine',
  'Climb the rock, make a home in the cleft',
  'But I trust T. William Walsh and I\'m not afraid to die',
  'Lucky and careful we dodged our worst fears',
  'Missing limbs beneath the cars',
  'Tears in someone\'s office',
  'From our house to the lake',
  'Blank look a foreign language',
  'I\'m up to my ears in tenors and altos',
  'Love well, young man',
  'Do I enjoy the drugs I take?',
  'To be the egg that laid the golden goose',
  'And the worn-out knees in your blue jeans',
  'Miles down below the skin, endless hidden reservoirs',
  'Shut some breakers off but not the whole box',
  'While the specters hover whispering',
  'All of us need major healing',
  'I wake up on the phone',
  'In the elevator her heart began to pound',
  'But I couldn\'t read the signs',
  'Feeling sorry for yourself',
  'I pay the bills',
  'I still sit in my bedroom alone with a shot gun',
  'And blur the lines of your ',
  'The class war is over and everyone wins',
  'A decent human being',
  'For an innocent kiss on the cheek',
  'And your daughter, she called your bluff',
  'I might as well admit it',
  'Money sits up front',
  'Watch pay-per-view and relax',
  'Beaten, gagged, and bound',
  'But I hoped that you would change your mind by autumn',
  'A false binary when it\'s probably something in between',
  'A bee sting swells up for a day or two',
  'Where high tide lifts all boats',
  'And like her they\'re both full of yuletide cheer',
  'Power can be such a tease',
  'John Henry dies in a tunnel',
  'I did my best to make them proud',
  'How you could tell the truth',
  'After graduation, will there be no going back?',
  'Got my hopes up pretty high',
  'My old man always swore that Hell would have no flame',
  'On the beach, drinkin\' beer',
  'I understand I can\'t sweat what mystery hides',
  'But now I get my kicks',
  'Know you\'ll stare at that face a long time before he winks',
  'Fresh from the soil',
  'Singing stories, whispering jokes',
  'What really threw me off',
  'I swear I learned it all from you',
  'A pair of ticket stubs in her desk - a movie I\'d never seen',
  'Skin and tragedy always attract a crowd',
  'Your father drank a little',
  'But they still can hear his voice',
  'I traded my own wisdom for a jury of my peers',
  'You can focus like a camera',
  'The motel',
  'Deep green hills, whose shoulders fade into thick grey',
  'Is that all these years I thought',
  'Valley floor, incandescent light',
  'Hold it in \'til your heart turns blue',
  'Ultra-violet rays are washing over all the boys and girls',
  'Your eyes glued to a screen',
  'When we tried to turn our volume down and muddle through the hush',
  'And she told me she loved me',
  'kill camel fuckers',
  'Paramedics brave and strong',
  'Looking at model homes again',
  'But you can\'t block it out',
  'They\'ve got the judge and you\'re convicted without a plea',
  'Tall wet grass, whose flesh makes fools of grazing sheep',
  'Don\'t dare write it down',
  'And a crown of diamonds',
  'Lying back on shoulder blades',
  'Where every hired gun I\'ve ever fired',
  'Satellites mediate for us the day\'s events through fiber optics',
  'If I’m going to retain my shape',
  'God bless the woman who weeps',
  'Engine severs lower legs',
  'Sitting here with pen and paper',
  'Her tiara digging deep in her head',
  'Child birth is painful',
  'Then it isn\'t making sense',
  'You shoulda\' taught him a lesson about being rude',
  'Going back and forth',
  'And you dream that the sound of his voice',
  'You wanna erase it',
  'She had to be twice as human, and just as tough',
  'But not by smoke or drink',
  'Man, it\'s getting heavy in here',
  'I hear Jesus and the angels singing',
  'With gray hairs on his head?',
  'So I tried to warn the others of the curse',
  'Waiting in their nearby spots',
  'Crazy people know',
  'Cause I\'m losing steam',
  'She opens her legs and --',
  'Hello again, Oblivion',
  'If you start without me say my name as you begin',
  'Can men and women not be friends?',
  'In other people\'s heads',
  'Pushing each other',
  'And when his tiny head emerged from blood and folds of skin',
  'Hard like a woman',
  'Let her know that I\'m alive',
  'Waxing since thirteen, wisdom from a beauty queen',
  'My uncle Ray was a ',
  'I need three pours just to walk through the door',
  'Shooting with straws and rubber bands',
  'And there\'s a steep slope',
  'The irony',
  'If God made the world',
  'And we painted over all the writing on the walls',
  'She asked me where my brother was',
  'You\'ve known it\'s over for a while',
  'As the priest got up to speak',
  'Airbags inflated, seat belts engaged',
  'To take a stand',
  'Dinner\'s on the table',
  'No one knows your name',
  'Is another\'s daily bread',
  'The great glad tidings tell',
  'Calling me to enter the Promised Land',
  'The roof is a vapor',
  'Smash it up flip it over \'til there\'s nothing to break',
  'I tried eternity and a couple of other drugs',
  'If I could abandon mine',
  'Odds are gonna get a little better every day',
  'While the one who loves me waits',
  'Now that\'s the sort of smack',
  'Rests on hope you gave that I\'ve some claim to your heart',
  'God bless the indian summer',
  'On long walks with my daughter',
  'The low slung houses',
  'And the names of every road',
  'The killer traveled eastbound in a golden-brown Sedan',
  'With their hands out stretched to shake',
  'And though he knew that Arizona wore the pants',
  'And the worn-out knees in your blue jeans',
  'And honor what exists',
  'The angel is always looking down',
  'You better believe I will sell my stuff',
  'On that we both agree',
  'For melting drive-thru soft serve',
  'So for her I try to go along',
  'While me and the Mrs exchange goodnight kisses',
  'Twelve flights down, nearly naked on the ground',
  'Like they accidentally drown',
  'This song playing on the radio',
  'So I go awhile and fake a smile and drink and drink and drink',
  'Rain falls on the animals',
  'We\'d split up the money',
  'Wrap my towel around me',
  'Every single one of you had better raise their right hand',
  'Sing that song at the top of your lungs',
  'Not quite home',
  'Rests on hope you gave that I\'ve some claim to your heart',
  'Whenever they went against the grain',
  'Than man you’ve gotta take stock',
  'New Mexico had always hated California',
  'Their tower to heaven',
  'If my mother cries when I tell her what I have discovered',
  'Don\'t waste your time',
  'A white ghost, making his way up the west coast',
  'Miles down below the skin, endless hidden reservoirs',
  'Just level with yourself',
  'And so he drove you around Chicago',
  'Woke up in the woods',
  'Putting poker faces on',
  'That\'s when it hit me what my old man meant',
  'Who shall I blame for this sweet and heavy trouble?',
  'When I touch down in Texas, land in Dallas/Fort Worth',
  'And three kings on bended knee',
  'But who he roots for in a fight',
  'It\'s ten o\'clock the sun has now',
  'In the elevator her heart began to pound',
  'I dream together',
  'Your grin poked through the door and we met lips',
  'Started making lists',
  'Or focus on the flaws',
  'Little twist of the compass',
  'Now that my blushing bride has done what she was born to do',
  'Her heart raced',
  'If you aren\'t moving units',
  'Looking down from that stain glass steeple',
  'When you chose the scale',
  'What it means or if the snowy twilight gleans',
  'Then later plays them back',
  'He sells me a fix',
  'Steam drill lives on to make fools of every man',
  'With Mexican and Negro',
  'When there\'s so much at stake',
  'Of the booze on my breath like her mom',
  'Could you play the five chord',
  'You sold yourself a trope',
  'Behind our backs',
  'We were begging for mercy',
  'We both knew her words were in vain',
  'All of the above is true',
  '"Live to fight another day"',
  'Marry me',
  'More rain than the cloud can hold',
  'That maybe Mr. Hole Fixin\' Man might have what I need',
  'Your lovely head might just be clear enough',
  'I\'m on the couch worried that we\'re through',
  'In your old paint scrubs in the doorway',
  'Ain’t it a shame',
  'If you\'re wondering where I\'ve been',
  'For black and for white',
  'My first two-wheel bicycle stood by the tree',
  'And death is coming fast',
  'Shuffling our shoes on brand new carpet',
  'As Jesus takes my hand',
  'Could crash into buildings',
  'Could you patch me through?',
  'I go to sleep on an airplane',
  'On the cover of a magazine?',
  'Standing at the altar',
  'And cut a hundred river beds in valley floors below',
  'Could someone please tell me the story',
  'Oh, I had a hard time falling asleep that night',
  'At least then I would not serve',
  'A hundred yards up blocking 3 lanes',
  '"For hate is strong and mocks the song',
  'And peace on the earth',
  'To watch your true love pack her things and drive away',
  'The cargo rushing past',
  'Didn\'t the disappointment press',
  'Sparkling water',
  'Certain tales of bravery',
  'By the cunning of these fully evolved men?',
  'That hangs in the air',
  'Love to splash in the waves',
  'First freedom, second life',
  'Thinking it\'s their fault',
  'Three more to choke it all down \'cause we both know that I\'ll never leave',
  'Click clack, now handicapped',
  'In the elevator her heart began to pound',
  'So when she finally picks me up',
  'Your former glory',
  'Nothing aches or lies in wait',
  'Where the wheel stops, no one knows',
  'Baby can do no wrong',
  'Old loves not truer than new love',
  'Shuffling our shoes on brand new carpet',
  'Making the only sound that I could sing',
  'I clung to miracles I have not seen',
  'We get a little peek over enemy lines',
  'I\'m thinking back to a sensual act I enjoyed with a girl in my teens',
  'But now it\'s you who doesn\'t know what a dollar is worth',
  'I prayed and criеd and cried and prayed, still pissed off, still in pain',
  'In front of witnesses',
  'Oh, my sweet rapture',
  'I didn\'t trust myself to not be taken captive',
  'One of the first boots on the ground',
  'Until I can',
  'Bites on the ankle',
  'And now you\'re trying to pin the blame on me',
  'If you notice the millions of small holes',
  'All of the experts say you ought to start them young',
  'I beat them at their own dirty game',
  'What it means or if the snowy twilight gleans',
  'To dodge the draft once more',
  'The volunteers were tired',
  'You\'ll drink yourself silly night after night',
  'Then pealed the bells more loud and deep',
  'She makes us both some breakfast',
  'With their souls wide open',
  'When push comes to shove',
  'Then in despair I bowed my head',
  'An old lyric, misremembered well',
  'With every little thing we need',
  'The faithful knelt in worship',
  'Why they treat you so',
  'Nothing lasts forever',
  'She said please daddy, please, daddy please',
  'They\'re comin\' up on your seven',
  'With tears in my eyes, I looked up at my dad',
  'The problem with rules',
  'You get emotional over empty cups',
  'Wondering if she’d soon despise the smell',
  'Neurons re-align',
  'Power can be such a tease',
  'I mouth the words and I suggest you follow suit',
  'And start payin’',
  'Finally got some meat on those ribs',
  'The neighbors heard a fight',
  'If I\'m going to help you',
  'Got a little allowance from doing chores',
  '\'Round yon virgin mother and child',
  'Cause I’m gonna keep trying to get this right',
  'I guess now you know',
  'In your holy quest to be above reproach?',
  'With a wheel turning in my head',
  'And looks the other way',
  'Both hands over my eyes',
  'So you\'re made of flesh and blood',
  'Might take a couple of beats',
  'All of the experts say you ought to start them young',
  'Have all grown to be',
  'Stare me down, watch me fall',
  'Still keep it hid and grit my teeth like you showed me',
  'When he\'s sober',
  'Then your eyes turned green',
  'I\'m starting to think that I\'m kind of shy',
  'At first we made it every night',
  'Who are these people',
  'Then some come along and say "buddy, it don\'t work that way"',
  'Upon the ocean floor',
  'In which our very different wounds require the same remedy',
  'If it\'s twice, I don\'t',
  'How badly shook I was',
  'Kissed your head and told you not to worry',
  'Surprised him when she came home early',
  'A very Merry Xmas',
  'Later on we were tryna make an apple magic',
  'Down at the liquor store',
  'When you see the fruit as is hangs on the tree',
  'Till we slept on Christmas Eve',
  'Unpacked the manger scene',
  'Your horse is ready to ride when morning comes',
  'Might take a couple of beats',
  'When I close my eyes',
  'Another young man tells his story',
  'To keep my place in line',
  'I tell myself, "Today I\'ll make a change"',
  'Those two pairs of big blue eyes',
  'I told you then that I would never leave you',
  'Given the time I think she would have understood',
  'The storm blows into a flood, then disappears',
  'While I chase anyone',
  'I\'ve gotta get out of my body',
  'Clear as a bell are the short breaths',
  'I could tell you why I doubt it, and why I still believe',
  'My head’s under water',
  'In the middle of your chest',
  'For black and for white',
  'If you had been there then you\'d agree',
  'One month shy of six years old',
  'The worst thing I could think of',
  'The stars in the sky looked down where he lay',
  'Two kids, Mom and Dad, monsoon out backseat glass',
  'The moving truck, all loaded up',
  'But you might have given up',
  'I still have never seen you',
  'The wind blows up the dirt',
  'One of us wants to',
  'What mystery hides',
  'And when you find that truth don’t budge',
  'track homes open houses',
  'That I won\'t shoot up tonight',
  'I recreate in the usual way',
  'The seed and the spill',
  'Powerful taboo',
  '\'Cause watching the scenery bleed',
  'Where high tide lifts all boats',
  'Where you were worse',
  'I go on and on and on in a drone until even I\'m not listening',
  'If you\'re lucky they\'ll turn out as good as you',
  'If you got most of your needs met',
  'Are thinner than paper',
  'But it seems so very far away',
  'It\'s got me by the reins',
  'Oh Union Hills & 35th Avenue',
  'pillar of smoke',
  'A hundred yards up blocking 3 lanes',
  'And I write it down for what it\'s worth',
  'Later on we were tryna make an apple magic',
  'Slam into brake lights',
  'Over cliffs, over the ocean',
  'When will I learn to obey, obey',
  'Lord knows I\'ve tried to follow where He leads me',
  'I swung my tassel',
  'Mr. Hole Fixin\' Man, you fixed my friend can you fix me?',
  'But now might fetch an ounce of flesh',
  'Well, count on me to still be ill at ease',
  'You\'re on liver number two',
  'They pealed aloud your praise',
  'Their watch of wondering love',
  'Some folks are all blood and no guts',
  'When i lived alone i did as i please',
  'Steam drill lives on to make fools of every man',
  'But really it kills',
  'And I tried to play it cool',
  'Things fall apart again',
  'Keep a record of the earth',
  '"We\'re so sorry, sir',
  'Hear a knock at the door',
  'Though I\'m drunk again',
  'Let the good times roll',
  'I helped you find the perfect color',
  'Leaving me solo at the snack bar',
  'To be down in the polls',
  'When I broke the spell and said',
  'After your birth she brought you to the nursery',
  'Where the sunset lives',
  'Still, we lie jerking back and forth',
  'You blink your eyes and wonder how did it even get there',
  'Of root and wild beast',
  'You still lived on the street where you laid down for good',
  'Between Hilda\'s and our old house',
  'And have a vivid dream',
  'Right at each other with spare time to say',
  'I\'d trade my kingdom for someone to ride with',
  'Bright smiles are watching his toddler run speed trials',
  'This brown liquor wets my tongue',
  'While you wept on the porch',
  ' hover above you',
  'I might have found someone who could love me',
  'Having to admit you made',
  'The sheets and the sweat',
  'And eat pizza all day',
  'They pervert the words of godly men',
  'That she might never thirst again',
  'So shave their legs and make them look like movie stars',
  'Where the wheel stops, no one knows',
  'I swear to you',
  'Counting down the hours \'til the sun came up again',
  'Some cases are open and shut',
  'Can\'t help wondering why it still feels like',
  'But as it turns out',
  'The mermaids teach us',
  'He\'s perfect without frown',
  'I\'ll begin the explanation as to why she cannot breathe',
  'Out here turning circles trying to find the shore',
  'We argue about it',
  'How do you stop a rolling stone?',
  'And three kings on bended knee',
  'And no one is the wiser',
  'People are equal people are good',
  'I wish I could have seen their faces',
  'They took your money',
  'Just somebodies eating paper in a finished attic',
  'Desperate attempts to fan the flame without the fire',
  'When adulthood called our bluff',
  'Fancy rental car',
  'And i know that it’s dangerous to judge',
  'Stays in the frame',
  'About talking to you with such an attitude',
  'But now you feel like a child',
  'I asked her for a walk, but she had to be on her way',
  'Not a word since after the fall',
  'Cause if good is finally gonna trump',
  'And when I need, cause I can\'t see with human eyes',
  'Is what they said you\'d bring us',
  'To reach the goal',
  'Awake whatever\'s comin\' next',
  'I couldn\'t catch my breath',
  'Waxing since thirteen, wisdom from a beauty queen',
  'And you’ve gotta take your lumps',
  'And so happy Christmas',
  'She swore that she could explain',
  'Cursing taxes and the government',
  'Your horse is ready to ride when morning comes',
  'Then she tried to hide the whole thing from New Mexico',
  'We long for the truth',
  'A pair of ticket stubs in her desk - a movie I\'d never seen',
  'I thought to myself if he only knew, he would climb right back in',
  'Pity party',
  'Dad piano playing',
  'But either way it\'s stuck to your pillow',
  'Heavy things we think written in disappearing ink',
  'Now we\'ve established a lack of sight',
  'With their chests of gold and greed',
  'Misconstrued my words so clear',
  'And going just outside the lines',
  'She wakes you up wearing an outfit',
  ' hover above you',
  'All the old fight is so quickly forgotten',
  'Maybe vision\'s the right word',
  'The fruit fly sighs, as evening fades',
  'Hey Mr. Hole Fixin\' Man, if you\'ve got proof I will believe',
  'But even with my conscience clear',
  'Cast out our sin and enter in',
  'Don’t it seem like nothing ever grows',
  'The cloud of witnesses hovers above you',
  'Thanks to the new American queen',
  'I could tell you why I doubt it, and why I still believe it',
  'Hello, Operator',
  'You threw up and shit the bed',
  'I spent my life dreaming',
  'So he takes another drink',
  'While the one who loves me waits',
  'Your father drank a little',
  'We got our model home',
  'If I still see desert roads',
  'Who sees me being sweet',
  'To have loved completely',
  'If you had been there then you\'d agree',
  'Bands with managers are going places',
  'And there\'s a steep slope',
  'But not much of the pain we heaped on each other for all those years',
  'Twelve points was an awful lot',
  'I won\'t ever lock my doors',
  'Do I drive around with my high beams on?',
  'To the city that he loves',
  'A white ghost, making his way up the west coast',
  'Now we\'re living in our houses with some different women',
  'Sunken ships rest',
  'To quiz me on books i did not claim to read',
  'Aunts and uncles are all there',
  'Shouldering the phone',
  'Waking or sleeping, thy presence my light',
  'To which I in pride responded',
  'Indian-style besides you',
  'The way is too steep',
  'What I hoped would be',
  'Where the redwoods live',
  'My Christmas-crazy girlfriend\'s parents love me',
  'But falling into my bed at night',
  'Convenient new inventions bait the tease',
  'You know I want to be like Jesus',
  'Just begun to set the western hills on fire',
  'And I still want to trust you',
  'The bully always wins',
  'And prolong the pain of being alive',
  'One could flee to the north',
  'So shave their legs and make them look like movie stars',
  'If you let down your guard',
  'The flags you wave around have got me wondering',
  'Put down your guitar, go enjoy her right now',
  'I gave you boundaries to save you from certain death',
  'Because the fruit is almost black',
  'Blew up these lungs',
  'Fever to find the scapegoat fast and fix the blame',
  'My jail shoes on',
  'Fever to find the scapegoat fast and fix the blame',
  'Make a home in the cleft',
  'Under the water',
  'Holy or unholy ghost well now i can’t tell but either way you cut it',
  'Trying to focus his high hopes ',
  'You knew which notes to bend',
  'Into each similar theme',
  'Smash it up flip it over \'til there\'s nothing to break',
  'I know you never meant to leave the way you came',
  'We hug and kiss and open gifts to Christmas songs',
  'Old lovers just know what it means',
  'You wanna erase it',
  'Sacrifice his shins and feet to set his torso free',
  'Then if you get tired of making taste for free',
  'The sperm swims for the egg; the finger for the ring',
  'Like a stand-up who forgets his punchlines',
  'And someone else is gonna make the call',
  'Lucky and careful we dodged our worst fears',
  'The path of least resistance',
  'When every other start requires a brand new thesis',
  'Rest in Me, little David and dry all your tears',
  'Until the snow is white with ocean foam',
  'First you have to blow it',
  'If I could just stop spinning',
  'Well, you can take that sting',
  'Into the pleasure centers of my brain',
  'Another young man tells his story',
  'O come to us, abide with us',
  'On the River Jordan',
  'To the great big sign that says \'oversize load\'',
  'If what they did was wrong that day',
  'The walls of our house',
  'I\'m falling on my sword',
  'Ultra-violet rays are washing over all the boys and girls',
  'Oh, look you earned your wings',
  'But now might fetch an ounce of flesh',
  'It\'s time to bury dreams and raise a son to live vicariously through',
  'As they strapped his arms down to his sides',
  'We drove all over town as the days blurred around us',
  'I wanna lay on the desert floor',
  'For what it\'s worth',
  'In the shadow of the mountain as the cattle hung their heads',
  'You sit at home I board another flight',
  'If you cleared your stuff on out by five',
  'Little clues half-hidden',
  'You\'ve heard the story',
  'The ache in her voice moved me',
  'Later on we were tryna make an apple magic',
  'Who sees me being sweet',
  'Eating my shame',
  'All fallen leaves should curse their branches',
  'Or focus on the flaws',
  'If you notice the millions of small holes',
  'I wish I\'d known better',
  'Could be twenty years',
  'For me to un-feel a thing',
  'Clothes scattered on the floor',
  'Man you don\'t need to chase the sound',
  'But you did not quite make the cut this time',
  'He said son, you\'re still young',
  'Fewer moving parts means fewer broken pieces',
  'Float to the surface',
  'Cause who makes up the difference',
  'Who even looks at me',
  'Jingle bells, jingle bells',
  'And I guess I could be bigger but I\'d rather make you pay',
  'Is my blurry vision\'s greatest enemy',
  'It finally shows',
  'Doing your best is enough',
  'Are you an angel, now',
  'Especially in the major cities',
  'Steam drill lives on to make fools of every man',
  'For her plan to break it off',
  'Raging after the white whale',
  'My family would burn, then I awoke',
  'When I sleep, I\'m usually dreaming',
  'Three more to choke it all down \'cause we both know that I\'ll never leave',
  'Riches I heed not, nor man\'s empty praise',
  'When you set the table',
  'The river keeps repeating',
  'Making you the punchline of some random thing I wrote',
  'Oh it\'ll come \'round',
  'When I broke the spell and said',
  'Singing stories, whispering jokes',
  'I was so proud just to have you sitting with me',
  'Is a kick in the teeth',
  'Sitting side by side on the piano bench',
  'When you see the fruit as is hangs on the tree',
  'To drink and do drugs',
  'Windows covering one wall of the store',
  'Or else we both might betray our inner lives',
  'Than we know how to speak',
  'Crisis posed a question',
  'Until they get to where they\'re going',
  'Bands with messy hair and smooth white faces',
  'Sitting alone here in my grown-up mess',
  'For peace on earth goodwill to men',
  'And all the while',
  'Stays in the frame',
  'Cause I\'ll need your signature before you leave',
  'And if I did I might wind up in hell',
  'You know how much it hurts',
  'Somehow I never went back home',
  'Bones are gonna shatter',
  'You think without me you\'ll get on much better',
  'What if everybody walks away',
  'And there was no one there to clean you up',
  'Is another\'s daily bread',
  'To save us all from Satan’s power',
  'The weight of the world is bearing down on me',
  'Deep green hills, whose shoulders fade into thick grey',
  'Man you don\'t need to chase the sound',
  'God bless the man who yields to temptation',
  'Do I enjoy the drugs I take?',
  'Cause man i’ve gotta get there on my own',
  'What i claim to be',
  'Holding hands around the couple skate',
  'I consider getting sober',
  'Pretty soon we\'ll be living in a covered wagon',
  'Everybody knows the songs are on their way out',
  '"Oh baby girl. Merry Christmas!"',
  'Than to be an ocean',
  'And when you chose the scale',
  'My faith in zeroes and ones to host our romance',
  'track homes open houses',
  'With no margin of error',
  'Still you hide your hope and hedge your bets',
  'You hide it under a bushel but the light comes shining through',
  'Sleep on the sofabed nearly a month',
  'I face my feelings alone',
  'Eating paper I swung from the trees',
  'Pulling me along like a sequencer',
  'Scissors falls apart',
  'Like a pillar of smoke',
  'Into each similar scene',
  'And I heard when each one left, and broke your heart',
  'The devil beats his wife again',
  'Just one more touch',
  'But once I start I can never shut my mouth',
  'When we both know you\'re at a motel',
  'If it comes from lower down',
  'Which will make you bad in different ways',
  'Lest we hear it from the rocks and the trees',
  'The storm blows into a flood, then disappears',
  'That no one wants to hear the truth',
  'Your horse is ready to ride when morning comes',
  'Hello from down below',
  'Compartments came apart then',
  'God bless the woman who suffers',
  'Virginia I had a dream we were closer than brothers',
  'Because you don\'t believe He talks that way',
  'Will you spend your whole life',
  'Remembering all of the times she’d nearly given up',
  'From sheer embarrassment',
  'It\'s time to blow off some steam',
  'Before the long dark storm',
  'Our savior\'s birth',
  'But everybody knows',
  'Right on time',
  '"Merry Christmas Daddy!"',
  'And I could write you off so easily',
  'Tall wet grass, whose flesh makes fools of grazing sheep',
  'She recognized in those pieces a brokenhearted man',
  'If you\'re lucky they\'ll turn out as good as you',
  'So shave their legs and make them look like movie stars',
  'Firmly back and forth they run',
  'Later on, we went out walking',
  'Do I drive around with my high beams on?',
  'But once I start I can never shut my mouth',
  'But you can always smell ',
  'Close enough',
  'And pay whatever it costs',
  'He seems nice',
  '"How\'d it go today" comes a question in the quiet car ride home',
  'And your little lady wants a car and a house',
  'If only the rules were built upon',
  'Deep into the woods',
  'Like it\'s the only way we learn',
  'Why would would you sweat my confession',
  'There\'s money behind every picture in the place',
  'With your head in your hands',
  'Several friends came to his grave',
  'Thinking it\'s their fault',
  'You gotta wait some more',
  'Causing the doubt to begin',
  'Tall wet grass, whose flesh makes fools of grazing sheep',
  'Husband\'s lost a lot of blood',
  'The killer traveled eastbound in a golden-brown Sedan',
  'If I swung my tassel to the left side of my cap',
  'Steam drill lives on to make fools of every man',
  'The judge says if I call them',
  'My first two-wheel bicycle stood by the tree',
  'Putting poker faces on',
  'Wondering how our lives would be',
  'Tremblin\' a little, I wait to be found',
  'And brings all the gifts to bed',
  'The revelation right in front of me',
  'Mosquito bites itch for a week',
  'She merely asked you a question',
  'Shine on, Lighthouse',
  'Shrink along the corridors, feeling out of place, feeling like a fool',
  'She had to be twice as human, and just as tough',
  'And you broke the machine',
  'Pierce the yolk and watch it bleed together',
  'By the cunning of these fully evolved men?',
  'She gathered herself and crept back to the truck',
  'And most everything turns to shit',
  'And watch their faces glow',
  'Climb the rock',
  'The fruit fly sighs, as evening fades',
  'Some folks are loners',
  'Or some drunk ship captain',
  'Wondering how our lives would be',
  'We argue about it',
  'If I still wanna trade in some nuclear woes',
  'A child who\'s always bragging',
  'After we\'d scrubbed the walls',
  'To see my dad down on his knees',
  'And build it up again',
  'The way you laughed at me threw off a little spark',
  'He tells himself, "Today I\'ll make a change"',
  'At least then I would not serve',
  'Go early to their new self-making beds',
  'This doesn\'t favor me',
  'Every single one of you have given something to hide',
  'I ride back home on a horse',
  'He\'s been awake',
  'I\'ll keep on \'till you agree to come back over',
  'Again at risk of being bored by clear thought',
  'White light',
  'The whole town is one step behind you',
  'If you were here I would admit that I\'m an asshole',
  'And gather up firstfruits to bring',
  'If someone tells you the truth',
  'No doubt about it',
  'Except a hundred million other people agree',
  'The ache in her voice moved me',
  'And, gathered all above',
  'And peace to men on earth',
  'Tomorrow I swear, I won\'t act this way',
  'Tonight we\'ll dine out on the front porch',
  'cloud of witnesses',
  'And build it up again',
  'Close your eyes and count to ten',
  'When he wakes up in the morning',
  '\'Cause ever since Beverly Hills Cop, "The Heat Is On"',
  'Again at risk of being bored by clear thought',
  'If no heavy breath',
  'The bully loved her cactus',
  'And I\'m down at the track, I\'m just calling to check in',
  'But now might fetch an ounce of flesh',
  'How the light has dimmed',
  'My old man always swore that Hell would have no flame',
  'I mouth the words and I suggest you follow suit',
  'And describe where we are',
  'I tried to warn you, but it looks like I was wrong',
  'This feels so good',
  'Sacrifice his shins and feet to set his torso free',
  'I will trust my neighbors',
  'Or maybe a couple of airplanes',
  'So you did not hear the phone',
  'It never comes to stay',
  'Barely ever fight, she knows that I love her',
  'Crooked lines just aren\'t that easy to plot',
  'For a hive where the drones eat the queen',
  'Bitten twice by rattlesnakes',
  'To a slow song that still takes my breath away',
  'Killed a mother of three',
  'Crying out to Jesus',
  'Hello from down below',
  'You never laugh for someone else',
  'I’m orbiting North Mountain pretty close',
  'Stayin\' all night',
  'I hoped without a plan',
  'Black Canyon freeway',
  'Still sending and receiving',
  'Then you, my lord, can take the blame',
  'And why are some hellbent upon there being an answer',
  'Means fuck the gatekeeper',
  'Hung a ghost in the air',
  'My heart was hard and tired',
  'Says it\'s gonna be alright',
  'Might make it out of this alive',
  'A shadow on the water',
  'But, darling, death will have to pry my fingers loose',
  'The hopes and fears of all the years',
  'From the evening until the dawn',
  'My first drum set',
  'More than you could chew',
  'I can\'t help this flood of tears',
  'Please hurry',
]
