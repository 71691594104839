import { SONG_DB } from '../../constants/songdb'

type Props = {
  songId: string
}

type ButtonProps = {
  songId: string
  buttonClick: () => void
}

type SongDb = {
  artists: { [key: string]: Artist }
  albums: { [key: string]: Album }
  songs: { [key: string]: Song }
}

type Artist = {
  artistId: string
  artist: string
}

type Album = {
  albumId: string
  artistId: string
  album: string
}

type Song = {
  songId: string
  artistId: string
  albumId: string
  song: string
}

export const SongInfo = ({ songId }: Props) => {
  const db = SONG_DB as SongDb
  const song = db.songs[songId]
  const album = db.albums[song.albumId]
  const artist = db.artists[song.artistId]
  return (
    <div className="flex flex-col items-center">
      <span className="p-0">🎵 {song.song}</span>
      <span className="p-0">🎤 {artist.artist}</span>
      <span className="p-0">💿 {album.album}</span>
    </div>
  )
}

export const SongButton = ({ songId, buttonClick }: ButtonProps) => {
  const db = SONG_DB as SongDb
  const song = db.songs[songId]
  return (
    <button
      type="button"
      className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
      onClick={buttonClick}
    >
      {song.song}
    </button>
  )
}
