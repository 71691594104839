import Countdown from 'react-countdown'
import { StatBar } from '../stats/StatBar'
import { Histogram } from '../stats/Histogram'
import { GameStats } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
import { tomorrow, solutionIndex } from '../../lib/words'
import { BaseModal } from './BaseModal'
import {
  STATISTICS_TITLE,
  GUESS_DISTRIBUTION_TEXT,
  NEW_WORD_TEXT,
  SHARE_TEXT,
} from '../../constants/strings'
import { SongButton, SongInfo } from '../stats/SongInfo'

type Props = {
  isOpen: boolean
  handleClose: () => void
  solution: string
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  lyric: string
  songId: string
  wrongSongs: string[]
  guessedRightSongTitle: boolean
  guessedWrongSongTitle: boolean
  setGuessedRightSongTitle: () => void
  setGuessedWrongSongTitle: () => void
  numberOfGuessesMade: number
}

export const StatsModal = ({
  isOpen,
  handleClose,
  solution,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
  lyric,
  songId,
  wrongSongs,
  guessedRightSongTitle,
  guessedWrongSongTitle,
  setGuessedRightSongTitle,
  setGuessedWrongSongTitle,
  numberOfGuessesMade,
}: Props) => {
  if (gameStats.totalGames <= 0) {
    return (
      <BaseModal
        title={STATISTICS_TITLE}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <StatBar gameStats={gameStats} />
      </BaseModal>
    )
  }

  // we may not end up needing the wrongSongs stuff, but let's
  // prep it, just in case
  // we'll sort the songs by their ID values instead of randomly, so
  // that any redraws won't cause the buttons to move around
  const songButtonIds: string[] = [...wrongSongs]
  songButtonIds.push(songId)
  songButtonIds.sort()
  const rightSongIndex = songButtonIds.indexOf(songId)
  const songButtonHandlers: (() => void)[] = Array(4)
  songButtonHandlers.fill(setGuessedWrongSongTitle, 0, 4)
  songButtonHandlers[rightSongIndex] = setGuessedRightSongTitle

  return (
    <BaseModal
      // TODO better modal titles? pull something from lyrics?
      title={
        !isGameLost && !isGameWon
          ? STATISTICS_TITLE
          : isGameWon
          ? 'Nice'
          : 'Bummer'
      }
      isOpen={isOpen}
      handleClose={handleClose}
    >
      {(isGameLost || isGameWon) && (
        <div className="mt-5 sm:mt-6 dark:text-white">
          {/* TODO this will have the full lyric and the link-outs and whatnot */}
          <p>
            <span className="font-bold">
              #{solutionIndex}:{' '}
              <span className={isGameWon ? 'text-green-500' : 'text-red-500'}>
                {solution}
              </span>
            </span>
            <br />
            {(guessedRightSongTitle || guessedWrongSongTitle) && (
              <span>
                {isGameWon ? '\u2705' : '\u274c'} WORD&nbsp;&nbsp;
                {guessedRightSongTitle && '\u2705'}
                {guessedWrongSongTitle && '\u274c'} SONG
              </span>
            )}
          </p>
          {(guessedRightSongTitle || guessedWrongSongTitle) && (
            <>
              <p className="text-xl p-3">&ldquo;{lyric}&rdquo;</p>
              <SongInfo songId={songId} />
            </>
          )}
          {!guessedRightSongTitle && !guessedWrongSongTitle && (
            <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
              Guess the Song!
              {/* TODO: animation after clicking the button */}
              <SongButton
                songId={songButtonIds[0]}
                buttonClick={songButtonHandlers[0]}
              />
              <SongButton
                songId={songButtonIds[1]}
                buttonClick={songButtonHandlers[1]}
              />
              <SongButton
                songId={songButtonIds[2]}
                buttonClick={songButtonHandlers[2]}
              />
              <SongButton
                songId={songButtonIds[3]}
                buttonClick={songButtonHandlers[3]}
              />
            </h4>
          )}
          <hr className="my-5" />
          {/* TODO gotta get better styling on this header (should more or less be the same as the modal header) */}
          <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
            {STATISTICS_TITLE}
          </h4>
        </div>
      )}
      <StatBar gameStats={gameStats} />
      <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
        {GUESS_DISTRIBUTION_TEXT}
      </h4>
      <Histogram
        gameStats={gameStats}
        isGameWon={isGameWon}
        numberOfGuessesMade={numberOfGuessesMade}
      />
      {(isGameLost || isGameWon) && (
        <div className="mt-5 sm:mt-6 columns-2 dark:text-white">
          <div>
            <h5>{NEW_WORD_TEXT}</h5>
            <Countdown
              className="text-lg font-medium text-gray-900 dark:text-gray-100"
              date={tomorrow}
              daysInHours={true}
            />
          </div>
          <button
            type="button"
            className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            onClick={() => {
              shareStatus(
                solution,
                guesses,
                isGameLost,
                isHardMode,
                isDarkMode,
                isHighContrastMode,
                handleShareToClipboard
              )
            }}
          >
            {SHARE_TEXT}
          </button>
        </div>
      )}
    </BaseModal>
  )
}
